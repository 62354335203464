import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo-650-alt.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import messenger from '../img/social/messenger.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter">
        <div className="content has-text-centered">
          <a href="https://charmelle.london" rel="noopener noreferrer" target="_blank">
            <img
              src={logo}
              alt="Charmelle London - Lip & Dermal Fillers Bromley"
              style={{ width: '14em' }}
            />
          </a>
        </div>
        <div className="content has-text-centered has-text-white-ter">
          <div className="container has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/services">
                        Services
                      </Link>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" className="navbar-item" href="https://charmelle.london/contact/">
                        Contact
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a target="_blank" rel="noopener noreferrer" title="facebook" href="https://www.facebook.com/charmellelondon/">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a target="_blank" rel="noopener noreferrer" title="Messenger" href="https://m.me/charmellelondon">
                  <img
                    className="fas fa-lg"
                    src={messenger}
                    alt="Messenger"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a target="_blank" rel="noopener noreferrer" title="instagram" href="https://www.instagram.com/charmellelondon/'">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
