import React from 'react'
import PropTypes from 'prop-types'

const Pricing = ({ data, offers = false }) => {
  if (!data) return null
  const link = (price) => !offers
    ? `https://charmelle.london/treatments/dermal-fillers/?add=${price.id}`
    : `https://charmelle.london/treatments/dermal-fillers/?add=${price.id}&code=DEFINED_LIPS`
  return (
    <div className="columns is-vcentered is-multiline" itemScope itemType='http://schema.org/Product'>
      {data.map(price => (
        <div key={price.service} className={`column ${offers ? 'is-one-half' : 'is-one-third'}`}>
          <section className="section">
            <h4 className="has-text-centered has-text-weight-semibold" itemProp='name'>
              {price.service}
            </h4>
            <span itemProp='offers' itemScope itemType='http://schema.org/Offer'>
              <meta itemProp='priceCurrency' content='GBP' />
              <link itemProp='availability' href='http://schema.org/InStock' />
              <span style={{ display: 'none' }}itemProp='seller' itemScope itemType='http://schema.org/Organization'>
                <span itemProp='name'>Charmelle London</span>
              </span>
              <h2 className="is-size-1 has-text-weight-bold has-text-primary has-text-centered">
                <span className="currency">£</span><span itemProp='price' >{price.price}</span>
              </h2>
            </span>
            <p itemProp="description">{price.description}</p>
            <div>
              <a
                className="btn has-text-weight-semibold pricing"
                target="_blank"
                rel="noopener noreferrer"
                href={link(price)}>Book</a>
            </div>
          </section>
        </div>
      ))}
    </div>
  )
}

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      service: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
    })
  ),
}

export default Pricing
