import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from "gatsby"

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <meta charset="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta content="#e7b23f" name="theme-color" />
        <meta content="website" property="og:type" />
        <meta content="en_GB" property="og:locale" />
        <meta content="Charmelle London" name="author" />
        <meta content="General" name="rating" />
        <meta content="en" name="Language" />
        <meta content="GB" name="geo.region" />
        <meta content="Bromley" name="geo.placename" />
        <meta content="51.410610;0.009650" name="geo.position" />
        <meta content="summary_large_image" name="twitter:card" data-react-helmet="true" />
        <meta content="Charmelle London" name="apple-mobile-web-app-title" />
        <meta content="Charmelle London" name="application-name" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={`${withPrefix("/")}img/filler-in-progress.jpg`} />

        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "@id": "https://www.lipfillers-bromley.co.uk/#organization",
              "name": "Charmelle London",
              "url": "https://www.lipfillers-bromley.co.uk/",
              "logo": "${withPrefix("/")}img/filler-in-progress.jpg",
              "foundingDate": "2018",
              "contactPoint": [{
                "@type": "ContactPoint",
                "telephone": "+442084648389",
                "contactType": "customer service"
              }],
              "sameAs": [
                "https://www.facebook.com/charmellelondon",
                "https://instagram.com/charmellelondon"
              ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
        {
            "@context" : "http://schema.org",
            "@type" : "LocalBusiness", 
            "logo": "${withPrefix("/")}img/filler-in-progress.jpg",
            "sameAs": [
              "https://www.facebook.com/charmellelondon",
              "https://instagram.com/charmellelondon"
            ],
            "image": [
              "${withPrefix("/")}img/filler-in-progress.jpg"
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "reviewCount": "24"
            },
            "currenciesAccepted": "gbp",
            "description": "Charmelle London provides semi permanent eyebrows, ombre brows, eyeliner, lip make up and aesthetics in Bromley, Kent. Charmelle London specialises in long lasting natural enhancements which are tailored to each individual client",
            "telephone": "+442084648389",
            "priceRange": [
              "5",
              "450"
            ],
            "contactPoint": [{
              "@type": "ContactPoint",
              "telephone": "+442084648389",
              "contactType": "customer service"
            }],
            "address" : {
              "@type": "PostalAddress",
              "addressLocality": "Bromley", 
              "addressRegion": "Kent", 
              "postalCode": "BR1 1DG", 
              "streetAddress": "37a London Road",
              "addressCountry":"UK"
            },
            "geo": {
              "@type":"GeoCoordinates",
              "latitude": 51.410610,
              "longitude": 0.009650
            },
            "name": "Charmelle London",
            "url": "https://www.lipfillers-bromley.co.uk",
            "email": "hello@charmelle.london",
            "openingHours": [
              "Mo-Sa 10:00-20:00"
            ],
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "20:00"
              }
            ],
            "map": "https://goo.gl/maps/361fweBFon62",
            "paymentAccepted": "Visa, Master Card, Amex, ApplePay, AndroidPay"
          }
        `}
        </script>
        <script type="application/json">{`
          {
            "@context":"https://schema.org",
            "@type":"WebSite",
            "@id":"https://www.lipfillers-bromley.co.uk/#website",
            "url":"https://www.lipfillers-bromley.co.uk",
            "name":"Charmelle London Semi Permanent Make Up and Aesthetics",
            "potentialAction": {
              "@type":"SearchAction",
              "target":"https://www.lipfillers-bromley.co.uk/?s={search_term_string}",
              "query-input":"required name=search_term_string"
            },
            "sameAs": [
              "https://www.facebook.com/charmellelondon",
              "https://instagram.com/charmellelondon"
            ]
          }
      `}</script>
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
