import React, {useState} from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo-650-alt.png'

import useSiteMetadata from './SiteMetadata'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import messenger from '../img/social/messenger.svg'

const Navbar = () => {
  const [state, setState] = useState({
    active: false,
    navBarActiveClass: '',
  })
  const data = useSiteMetadata()

  const toggleHamburger = () => {
    setState({
      active: !state.active,
      navBarActiveClass: !state.active ? 'is-active' : '',
    })
  }

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <h1>
            <Link to="/" className="navbar-item logo-item" title="Logo">
              <img src={logo} alt={data.title} />
            </Link>
          </h1>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${state.navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${state.navBarActiveClass}`}
        >
          <div className="navbar-start has-text-centered">
          <Link className="navbar-item" to="/services">
              Services
            </Link>
            <Link className="navbar-item" to="https://charmelle.london/about/">
              About
            </Link>
            <a target="_blank" rel="noopener noreferrer" className="navbar-item" href="https://charmelle.london/contact/">
              Contact
            </a>
          </div>
          <div className="navbar-additional navbar-end has-text-centered">
            <a  className="navbar-item" href="tel:+442084648389">Call us: 0208 464 8389</a>

            <a target="_blank" rel="noopener noreferrer" className="navbar-item" title="facebook" href="https://www.facebook.com/charmellelondon/">
              <img
                src={facebook}
                alt="Facebook"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
            <a target="_blank" rel="noopener noreferrer" className="navbar-item" title="Messenger" href="https://m.me/charmellelondon">
              <img
                className="fas fa-lg"
                src={messenger}
                alt="Messenger"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
            <a target="_blank" rel="noopener noreferrer" className="navbar-item" title="instagram" href="https://www.instagram.com/charmellelondon/">
              <img
                src={instagram}
                alt="Instagram"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
